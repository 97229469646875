<template>

  <div class="home uk-inline">
    <div class="slideul ">
      <div class="a1 uk-column-1-2">

        <article class="a1-container   uk-position-bottom-left uk-flex uk-flex-top uk-flex-center uk-flex-column">
          <div class="el1">
            <div class=" uk-overflow-hidden">
              <div class="limit uk-visible@m" uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true;scroll:true">CREAT DIN PASIUNE</div>
            </div>
            <h1 class="uk-article titlu uk-text-primary" uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true" style="animation-duration:0.7s">Experiența începe acum.

            </h1>
          </div>
          <div class="box uk-position-right uk-text-primary uk-flex uk-flex-middle uk-flex-center   uk-visible@s" uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true">
            <div class=" scroll uk-text-small">Continuă<span uk-icon="arrow-down"></span></div>
          </div>
        </article>

      </div>
    </div>
    <div class="sageata uk-text-primary">
      <span uk-icon="icon: arrow-down;ratio:2" class="uk-position-bottom"></span>
    </div>

    <div class="uk-height-1-1 uk-width-1-1 uk-position-absolute" style="top:0;">
       <video  width="320" height="240" class="video" autoplay="autoplay" muted loop  playsinline  >
            <source src="@/assets/animatieSticlaOriginal.mp4" type="video/mp4">
           <source src="@/assets/animatieSticla.webm" type="video/webm">
          <source src="@/assets/Animatiesticlaiphone.m4v" type="video/m4v">
           <source src="@/assets/animatieSticlaiphone.mp4" type="video/mp4">
        </video>
     </div>
    <div class="uk-width-1-1 uk-position-bottom-center sus">
      <img src="@/assets/val.png"  class="svggg uk-width-1-1" style="width:100vw">

    </div>
  </div>

</template>

<script>

  // @ is an alias to /src
  import HelloWorld from '@/components/HelloWorld.vue'

  export default {
    name: 'HomeView',
    mounted: function() {},
    components: {
      HelloWorld
    }
  }

</script>
<style scoped="" lang="scss">

  @import '@/assets/styles.scss';

  @media (orientation: portrait) {
    .logo {
      margin-top: 0.8em;
    }
    .titlu {
      margin-bottom: 2.5em;
    }
    .descriere {
      margin-bottom: 7em;
    }
    .home {
      overflow: hidden;
    }
    .slideul {
      height: 100vh;
    }
    .uk-slideshow-items {
      height: 100vh;
    }
    .background {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }

    .video {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      min-height: 70vh;
      width: 100%;
    }
    .svggg{
      width:102vw!important;
      transform:translateX(-1px) translateY(10px);

    }

  }
  @media (orientation: landscape) {
    svg{
      z-index: +99
    }
    .sus{
      z-index: +99;
      transform:translate(-50%,40%);
    }
    .svggg{
      height:30vh;
      width:100vw;
      z-index: +11;
    }
    .home {
      overflow: hidden;
    }
    .background {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
    .scroll {
      writing-mode: vertical-rl;
      letter-spacing: 5px;
      opacity: 0.8;
    }
    .el1 {
      width: 37vw;
      transform: translateY(-1em);
    }
    .el2 {
      padding-top: 3em;
      margin-right: 4em;
      height: 100%;
      width: 23vw;
      opacity: 0.8;
      letter-spacing: 3.5px;
      font-size: 1.2em;
      .el2-el {
        letter-spacing: 2px;
      }
    }
    .uk-text-muted {
      opacity: 0.8;
      letter-spacing: 1em;
    }
    .uk-article-meta {
      margin-left: 1em;
    }
    h1 {
      padding: 0;
      margin: 0;
      letter-spacing: 0.09em;
    }
    article {
      padding-left: 9vw;
      transition-duration: 0.3s;
    }
    li {
      width: 100%;
      height: 100%;
    }
    .slideul {
      height: 100vh;
      width: 100%;
    }
    .a1 {
      height: 100vh;
      z-index: 100;
      margin-bottom: 10vh;
    }
    .a1-container {
      width: 78vw;
      height: 25em;
    }
    .box {
      background: white;
      width: 2.5em;
      height: 18em;
      letter-spacing: 0.5em;
    }
    .a2 {
      background: white;
      height: 90vh;
      width: 80vw;
      opacity: 0;
      display: none;
    }
    .a2-container {
    }
    .titlu {
      margin-top: 0.5em;
      letter-spacing: 0.2em;
      font-size: 1.8;
    }
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.5s;
      display: block;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
      display: none;
    }
    .limit {
      letter-spacing: 0.8em;
      color: #cfcfcf;
    }
    li::marker {
      content: ' ';
    }
    h2 {
      padding: 1.5em;
      padding-top: 0.3em;
      padding-bottom: 1.5em;
      letter-spacing: 0.5em;
    }
    .video {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }

</style>
