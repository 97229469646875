<template>

  <div class="navbar-container uk-sticky">
    <div class="navmic uk-flex uk-flex-column">

      <div class="  logoContainer uk-background-primary uk-width-1-1">
      <div class="button titlu uk-h1 uk-text-secondary logo   " ><span>
       <img src="@/assets/logo.png"  width="" height="" alt=""></span></div>
      </div>

      <section class="uk-visible@m navmare uk-background-secondary uk-padding-small uk-text-primary uk-width-1-1 uk-flex">
        <a href="#despre" uk-scroll="offset:100" class="button navbar-link">Despre noi</a>
        <a href="#events" uk-scroll="offset:100" class="button navbar-link">Evenimente</a>
        <a href="#produse" uk-scroll="offset:300" class="button navbar-link">Produse</a>
        <a href="#proprietati" uk-scroll="offset:100" class="button navbar-link">Proprietăți</a>
        <a href="#siguranta" uk-scroll="offset:100" class="button navbar-link">Siguranță</a>
        <a href="#contact" uk-scroll="offset:100" class="button navbar-link">Contact</a>

      </section>
    </div>


  </div>

</template>

<script>

  import programare from '../components/programare.vue'
  export default {
    name: 'Navbar',
    components: {
      programare: programare
    }
  }

</script>

<style scoped lang="scss">

  @import '../assets/styles.scss';
  .buton {
    cursor: pointer;
  }
  .button {
    text-decoration: none;
  }
  .button:hover {
    text-decoration: none;
    color: white;
  }
  .buttonnav {
    margin-top: 1em;
  }
  .uk-offcanvas-bar {
    padding-bottom: 20vh;
  }
  .burger {
    margin-left: 1.5em;
  }
  @media (orientation: portrait) {
    .navbar-container{
      pointer-events: none;
    }
    .consultatii {
      border-radius: 1em 1em 0em 0em;
      padding: 0.5em;
    }
    .titlu {
    }
    .uk-offcanvas-reveal {
      width: 100vw !important;
    }
    .uk-offcanvas-bar {
      width: 100vw;
    }
    h2 {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 34px;
    }

    .logo {transform:translateY(-10vh);
      text-decoration: none;
      :hover {
        text-decoration: none;
      }
    }
    .navbar-container {
      top: 0;
      z-index: +99;
      height: 3em;
      position: relative;
    }
    .navbar-link {
      padding: 1em;
      color: white;
      opacity: 0.5;
      transition-duration: 0.3s;
      text-decoration: none;
    }
    .navbar-link:hover {
      opacity: 1;
    }

    .navbar-link {
      text-decoration: none;
      letter-spacing: 0.3em;
      font-size: 90%;
      display: inline-block;
      position: relative;
    }
    .navbar-link:after {
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      content: '';
      display: block;
      height: 2px;
      left: 50%;
      position: absolute;
      background: #0f7378;
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
      width: 0;
    }
    .navbar-link:hover:after {
      width: 100%;
      left: 0;
    }
    .logoContainer {
      width: 100%;
      z-index: 99;
      transform: translate3d(0, 0, 1);
      height: 50px;
    }

    .navmic {
      width: 100%;
    }
    .butonconsultatie {
      position: absolute;
      right: 0;
      margin-right: 3.5em;
      padding-top: 0em;
      background: $global-secondary-background;
    }
  }

  @media (orientation: landscape) {
    .butonconsultatie {
      margin-right: 100px;
    }
    h2 {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 34px;
    }
    .fundal {
      position: absolute;
      transition-duration: 0.3s;
      background: white;
      z-index: -1;
      width: 100%;
      height: 50px;
      top: 0;
    }
    .logo {height:50px;
      img{height:220px;
        transform:translateY(-50% + 14);
      }
      text-decoration: none;
      :hover {
        text-decoration: none;
      }
    }
    .navbar-container {
      top: 0;
      z-index: +99;
      height: 3em;
      position: relative;
    }
    .navbar-link {
      margin-left: 2.5em;
      opacity: 0.5;
      transition-duration: 0.3s;
      text-decoration: none;
    }
    .navbar-link:hover {
      opacity: 1;
    }

    .navbar-link {
      color: white;
      text-decoration: none;
      letter-spacing: 0.35em;
      font-size: 90%;
      display: inline-block;
      position: relative;
    }
    .navbar-link:after {
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      content: '';
      display: block;
      height: 2px;
      left: 50%;
      position: absolute;
      background: #0f7378;
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
      width: 0;
    }
    .navbar-link:hover:after {
      width: 100%;
      left: 0;
    }
    .logoContainer {
      width: 100%;
      height: 50px;
    }
    .navmic {
      position: fixed;
      width: 100%;
    }
    .navmare {
      width: 100vw;
      padding: 6px;
    }
  }
  .uk-offcanvas-overlay {
    background: white;
  }
  .navbar-container {
    width: 100%;
    position: absolute;
  }
  .uk-offcanvas-reveal {
    width: 100vw !important;
  }
  .uk-open {
    width: 100vw !important;
  }
  .consultatie {
    border-radius: 1em;
    padding: 5px;
    background: $global-secondary-background;
  }
  .uk-offcanvas-close {
    margin: 1.5em;
    transform: scale(1.5);
  }

</style>
