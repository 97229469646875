<template>
  <div class="main">
      <router-view class="view one" ></router-view>

</div>
</template>
<script>
</script>
<style lang="scss">
@import "@/assets/styles.scss";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,500;1,300;1,600&display=swap');
#app {font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  letter-spacing:1.1px ;
  top:-1;
  
image-rendering: high-quality;
}

body{margin:0;
top:0;
}
h1{margin:0;
}
h2{
  font-weight: bold!important;
  letter-spacing: 3px;
  font-size:2.3em ;
}
h3{font-weight: bold;

}
.uk-button{
  font-weight: 500;
}
.uk-offcanvas-reveal{
  width:100vw !important;
}
</style>
