<template lang="html">

  <div class="component-wrapper uk-text-secondary  uk-background-secondary ">
    <div class="background uk-text-center uk-flex uk-flex-center uk-flex-middle  uk-background-primary" uk-parallax="y: 60%;end:50vh">
      <div class="footer uk-flex uk-flex-column uk-flex-center uk-flex-middle">
        <div class="uk-background-secondary uk-position-top-center camioane-container uk-text-primary">
          <div class="maps uk-flex uk-flex-center uk-margin-top ">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55517.61074664945!2d23.38228987170191!3d47.885666372211624!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4737ed6997605c4b%3A0xafb60d1953ff3d73!2sStrada%20Victoriei%20320%2C%20Negre%C8%99ti-Oa%C8%99%20445200!5e0!3m2!1sro!2sro!4v1655731030244!5m2!1sro!2sro"

                    width="1980"
                    height="450"
                    style="border:0;position:relative;z-index:0"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe> </div>

      </div>
      <div class="media uk-background-primary">
        <div class="share uk-margin"></div>
        <div class="iconite">
          <a href="https://facebook.com/nova.oas/" class="facebook"><span class="iconita" uk-icon="facebook"></span>Facebook </a>
        </div>
      </div>
      <div class="contact uk-position-bottom-center uk-margin-bottom uk-background-primary">
        <span class="uk-text-bold">S.C. NOVA OAS S.R.L. </span><br> Negresti-Oas, jud. Satu Mare, Str. Victoriei nr. 320 <br>
        <span
        class="uk-text-bold">Tel./Fax:</span><a href="tel:+400261858400">0261-858-400</a> <br><span class="uk-text-bold"> Mobil:</span> <a href="tel:+400745667233">0745-667-233</a><br> <span class="uk-text-bold">E-mail:</span>        <a href="mailto:office@nova-oas.ro">office@nova-oas.ro</a>
      </div>

    </div>

  </div>
</div>
</template>

<script>

  import programare from '../components/programare.vue'
  export default {
    name: 'FooterView',
    mounted() {
      console.log('New component mounted.')
    },
    components: {
      programare: programare
    }
  }

</script>

<style scoped lang="scss">
.facebook{
    background: #3b5998;
    border-radius: 3px;
    font-weight: 600;
    padding: 5px 8px;
    display: inline-block;
    position: static;
    color:white;
    text-decoration: none;
  }
    .facebook:hover {
    cursor: pointer;
    background: #213A6F
}
  .maps{
  }
  .component-wrapper {
    overflow:hidden;
    max-width:100%;
  z-index: 0
  }
  .uk-link {
  }
  .background {
    padding-top: 20vh;
    position:relative;
    z-index: 0;
    height: 70vh;
    width: 100vw;
    top:-50%;
  }
  .footer {
    height: 20vh;
    width: 90%;
    padding-bottom: 2.5em;
  }
  .media {margin-top:7em;
  }
  .contact {
    line-height: 1.8em;
    letter-spacing: 2.1px;
  }
  .camioane-container {
    width: 100%;
    height: 33vh;
    letter-spacing: 10px;
  }

  @media (orientation:landscape){

    .contact{padding:1em}
  }
</style>
