<template>
  <div class="about uk-background-secondary uk-padding " id="about">
      <section class="sect" >

      <img src="@/assets/truck6.png" class=" camion-dreapta cam6 cam"   >
      <img src="@/assets/truck5.png" class=" camion-dreapta cam5 cam"   >
      <img src="@/assets/truck1.png" id="camion1" class=" camion-dreapta cam1 uk-position-absolute cam" >
      <img src="@/assets/truck2.png" class=" camion-dreapta cam2 cam"   >
      <img src="@/assets/truck3.png" class="camion-dreapta  cam3 uk-position-absolute@m cam" >
      <img src="@/assets/truck4.png" class="camion-dreapta cam4 cam uk-position-absolute"  >
      </section>
  </div>
</template>
<script>
export default{
  name:'OpticaView'

}
</script>
<style lang="scss" scoped>
.sect{
}
.parteneri{
min-height: 50vh;
}
.camion-stanga{
  transform:translateX(100vw);
position:absolute;
  animation: camion-stanga-translate 5s infinite;animation-timing-function: ease-in;
}
.camion-dreapta{
  transform:translateX(-100vw);
position:absolute;
  animation: camion-dreapta-translate 5s infinite;animation-timing-function: ease-in;
}
.camion2{
  animation-delay:3s;
}

.camion-stanga-translate{
  transition-duration: 4s;
}
.camion-dreapta-translate{
  transition-duration: 4s;
}
@keyframes camion-dreapta-translate{
  from {left:0px}
  to{left:200vw}
}

@keyframes camion-stanga-translate{
  from {right:0px}
  to{right:200vw}
}
@media (orientation:landscape){
.cam1{animation-duration:15s;animation-delay:0s}
.cam2{animation-duration:15s;animation-delay:2.5s}
.cam3{animation-duration:15s;animation-delay:5s}
.cam4{animation-duration:15s;animation-delay:7.5s}
.cam5{animation-duration:15s;animation-delay:10s}
.cam6{animation-duration:15s;animation-delay:12.5s}
@keyframes camion-dreapta-translate{
  0% {left:0px}
  100%{left:280vw}
}
}
@media(orientation:portrait){
.cam1{animation-duration:12s;animation-delay:0s}
.cam2{animation-duration:12s;animation-delay:2s}
.cam3{animation-duration:12s;animation-delay:4s}
.cam4{animation-duration:12s;animation-delay:6s}
.cam5{animation-duration:12s;animation-delay:8s}
.cam6{animation-duration:12s;animation-delay:10s}
.camion-dreapta{

    transform:translateX(-200vw);
  position:absolute;
  left:-100vw;
  animation-timing-function:linear;
  }
  @keyframes camion-dreapta-translate{
    0% {left:0px}
    50%{left:350vw}
    100%{left:350vw}
  }
}
.about{

    max-width:100vw;
    overflow:hidden;

}
.cam{
  width:25em;
}
</style>
