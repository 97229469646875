<template lang="html">
  <div class="component-wrapper">

    <div class="programare uk-flex uk-flex-middle uk-flex-center">
    <div v-if="programare1==='start'" >
      <div class="uk-margin">Programați-vă acum!</div>
      <div class="buton uk-button uk-background-secondary uk-text-primary uk-padding-small" @click="this.programare1='data'">Începeți</div></div>
    <div  v-if="programare1==='data'" class="uk-flex uk-flex-column uk-width-1-1">Alege data
        <section class="uk-flex">
          <div id="date"  class="butondata date uk-background-secondary uk-text-primary  uk-border-circle uk-flex uk-flex-middle uk-flex-center" v-for="programare in programari" >
                <div @click="butonpush(programare.ziua)" >{{programare.ziua}}</div>
          </div>
        </section>
        <section class="uk-flex uk-child-width-1-6">
          <div :id="programare.ziua" class="ore " v-for="programare in programari">
            <div class="butoaneore "  v-for="oraa in programare.ore"  >
              <input type="radio"  :id="oraa.ora" name="unNume">
            <label :id="oraa.ora"  :for="oraa.ora" >  {{oraa.ora}}</label>
            </div>
          </div>
        </section>


      <div class="buton uk-button uk-background-secondary uk-text-primary uk-padding-small uk-align-center" @click="this.programare1='contact'">Mai departe</div>
    </div>
    <div  v-if="programare1==='contact'">Introduceți date de contact
      <div class="coloanaEl uk-flex uk-flex-column uk-margin-bottom">
        <input class="uk-margin-top" v-model="firstName" placeholder="numele ">
        <input class="uk-margin-top" v-model="telefon" placeholder="numărul de telefon">
      </div>
      <div class="buton uk-button uk-background-secondary uk-text-primary uk-padding-small" @click="this.programare1='data'">inapoi</div>
      <div class="buton uk-button uk-background-secondary uk-text-primary uk-padding-small" @click="this.programare1='gata'">Programare</div>
    </div>
    <div  v-if="programare1==='gata'">Programarea a fost făcută</div>

  </div>
  <div id="existNuMaSterge"></div>
  </div>
</template>

<script>
import UIkit from 'uikit'
import axios from 'axios';
  export default {
    mounted() {
      console.log('New component mounted.');


    },
    data:function(){
      return{i:0,
        programare1:'start',
          firstName:'',
          programare:'',
          butonverde:false,
          telefon:'',
          locatie:'la optica',
          trimis:'Click pentru a finaliza',
          dataaleasa:'existNuMaSterge',
          oraaleasa:'',
          programari:[
            {ziua:'a1',
            ore:[
              {ora:'12:30'},
              {ora:'13:50'},
              {ora:'12:40'},
              {ora:'12:20'}
            ]},
            {ziua:'b2',
              ore:[
                {ora:'12:30'},
                {ora:'14:30'},
                {ora:'15:30'},
                {ora:'17:30'}
              ]},
              {ziua:3,
              ore:[
                {ora:'12:30'},
                {ora:'12:30'},
                {ora:'12:30'},
                {ora:'12:30'}
              ]},


          ]
      }
    },
    methods:{
      setdata1: function(el){
        this.firstName = el
      },
        setdata2: function(el){
          this.programare = el
        },
      butonpush:function(el){
      document.getElementById(el).classList.toggle("butonulactiv");
      document.getElementById(this.dataaleasa).classList.toggle("butonulactiv");
        this.dataaleasa = el;
      },
    orapush:function(el){
    document.querySelector(el).classList.toggle("oraactiva");
    document.querySelector(this.oraaleasa).classList.toggle("oraactiva");
      this.dataaleasa = el;
    },
      formPost: function(){
          var bodyFormData = new FormData();
          bodyFormData.append('firstName', this.firstName);
          bodyFormData.append('programare', this.programare);
          bodyFormData.append('telefon', this.telefon);
          bodyFormData.append('locatie', this.locatie);
          this.butonverde = true;
          this.trimis = "Programarea a fost trimisă. Veți fi contactat pentru confirmare";
                axios({
          method: "post",
          url: "http://4lecxandru.alwaysdata.net/php/mail.php",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then(function (response) {

          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });
      }
    }

  }
</script>

<style scoped lang="scss">


@media (orientation:portrait){
  .programare{
    height:50vh;
    width:80vw
  }
}
@media (orientation:landscape){
  .programare{
    height:50vh;
    width:40vw
  }
}
.buton{width:200px}
.uk-button-primary{
  cursor:pointer;
}
.uk-button-primary:visited{
  background-color:red
}
.ore{
  display:none;
  opacity:0;
  transition-duration: 0.7s;
}
.butonulactiv{
  display:flex;
  transition-duration: 0.7s;
  opacity:1;
}
.butoaneore{
  margin:1em;
  cursor:pointer;
  padding:0.5em;
  border-radius:2em;
}
.date{width:2em;
height:2em;
cursor:pointer;

}
.active{background-color:#0F7378;
color:white;

}
.oraactiva{background-color:#0F7378;
color:white;

}
input[type=radio]{
  display:none
}
label {
  display: inline-block;
  border-radius:2em;
  border:2px 2px solid #0F7378;
  padding: 4px 11px;
  font-family: Arial;
  font-size: 16px;
  cursor: pointer;
}

 input[type="radio"]:checked+label {
  background-color: #0F7378;
  color:white;
}
</style>
