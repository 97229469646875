<template lang="html" class="">

  <div class="component-wrapper justify">
    <navbar></navbar>
    <HomeView class="view one uk-background-primary"></HomeView>
    <div class="view two uk-background-secondary uk-text-primary uk-overflow-hidden">
      <section id="despre" class=" despren uk-container uk-padding ">
      <span  class="uk-container uk-padding uk-card uk-background-primary uk-text-secondary uk-border-rounded uk-box-shadow-large">
        <div class=" uk-padding"><h2 class="despretitlu uk-margin-top uk-padding-small">Despre noi</h2>
          Compania noastră a fost înființată în anul 1994, iar în cei aproape 30 de ani de activitate ne-am dezvoltat și am încercat pas cu pas să ne adaptăm nevoilor pieței, atât locale, cât și naționale în măsura în care resursele noastre ne-au permis acest lucru.
Suntem o societate cu 100% capital privat, începută ca o afacere de familie, tradiție pe care ne străduim să o ducem mai departe în fiecare zi, cu fiecare pas pe care-l facem...</div>
        <br><button uk-toggle="target: #showww22" class="uk-button uk-background-secondary uk-text-primary uk-border-rounded uk-margin uk-box-shadow-small uk-box-shadow-hover-large"
                type="button">Arată tot</button>
        <div id="showww22" hidden class=" uk-padding justify"> <p>
La început, activitatea noastră s-a desfășurat cu focus major pe construcțiile hidrotehnice și pe diferite lucrări speciale în domeniul construcțiilor, iar 10 ani după înființare, în 2004, am început să reinvestim profitul realizat în activitatea de îmbuteliere a apei minerale.
Acesta a fost momentul în care am început și construcția unei fabrici pe care, în timp, am reușit să o dotăm cu o linie de îmbuteliere modernă, pas pe care l-am realizat alături de compania AVE Industries Spa ITALIA, care deja la vremea respectivă avea un renume în acest domeniu.
</p><p>Premergător acestei etape, încă din anul 1996 s-au făcut practic primele foraje de tip F8 și F9, desigur cu scopul de a realiza în timp comercializarea de apă minerală.
După dotarea fabricii cu linia de îmbuteliere modernă în anul 2004, doi ani mai târziu în 2006, forajelor F8 și F9 li s-a mai adăugat un al treilea, și anume forajul de tip F10, care a fost unul de mare adâncime și care împreună ne-au permis să putem asigura necesarul de apă pentru îmbuteliere.
</p><p>Câțiva ani mai târziu, în 2009, am reușit să mai parcurgem o etapă în producția și îmbutelierea de apă prin prisma noii linii de îmbuteliere, de această dată de o mai mare productivitate, primind sprijin de la producătorul NATE din Cehia. Încă doi ani mai târziu, în 2011, compania noastră a achiziționat noua linie de îmbuteliere, care ne-a permis îmbutelierea apei în ambalaje de 330 și 700 ml atât pentru apa minerală, cât și pentru cea plată, alături de celelalte deja existente.
Astăzi, compania Nova Oaș se străduiește să le ofere consumatorilor și iubitorilor de apă naturală sănătoasă, produse la cele mai înalte standarde și la un raport calitate-preț cât mai bun cu putință.
</p><p>Pentru noi este foarte important ca ceea ce ajunge pe mesele consumatorilor să fie de bună calitate și să satisfacă și mulțumească nevoile pe termen lung.
Cu toate că scopul este de a ajunge la cât mai multe persoane din cât mai multe județe din țară, nu putem nega faptul că județul Satu Mare este sursa izvoarelor și puterilor noastre, astfel că deservirea populației din județ este printre valorile noastre cele mai importante.
</p>
        </div>
        </span>
      </section>
    </div>

        <div id="events" class="view  uk-background-secondary uk-text-primary "><br>
          <h2 class="uk-text-primary  ">Evenimente</h2>
<div class="uk-width-2-5@m uk-margin-auto uk-padding justify uk-margin-bottom">
Compania noastră a înțeles că participarea activă în viața socială a mediului colectiv din care facem parte este extrem de importantă, deoarece susținerea vieții și a calității aceste este primordială pentru noi.<br>
Așadar, ne străduim să fim prezenți în cât mai multe locuri, la cât mai multe evenimente publice sociale și mai ales sportive, pentru a sprijini comunitatea sătmăreană și nu numai.<br>
Mai jos găsiți doar câteva dintre evenimentele la care am participat, iar pentru a fi la curent cu activitatea noastră, ne puteți urmări și pe rețelele de socializare<br><a href="https://www.facebook.com/oas.apa" class="uk-text-primary" target="_blank">Facebook</a> și Instagram.
</div>
          <div uk-slider="autoplay:true;autoplay-interval:3000;center:true"  class="uk-padding-small uk-margin-top">
            <ul class="tabelelemente uk-slider-items uk-child-width-1-1   uk-child-width-1-2@m  uk-margin-top"  >
              <li>
                        <div uk-toggle="target: #my-id" type="button" class="buton-modal uk-overflow-hidden uk-border-rounded">
                        <img src="@/assets/EvenimenteOas/eventoas14.webp" class="uk-overflow-hidden uk-border-rounded  "  width="" alt="">
                          <div class="uk-position-bottom-center uk-width-1-1 uk-text-secondary uk-background-primary uk-padding-small uk-border-rounded overlayapa fontnormal">Zilele Oașului</div>
                      </div>
                                <!-- This is the modal -->
                          <div id="my-id" uk-modal>
                              <div class="uk-modal-dialog uk-modal-body uk-flex uk-flex-column uk-flex-middle">
                                    <h2 class="uk-modal-title"></h2>
                                    <img src="@/assets/EvenimenteOas/eventoas1.webp" class="elementmodal"  width="" alt="">
                                    <img src="@/assets/EvenimenteOas/eventoas2.webp" class="elementmodal" width="" alt="">
                                    <img src="@/assets/EvenimenteOas/eventoas3.webp" class="elementmodal" width="" alt="">

                              </div>
                            </div>

              </li>
              <li>
                        <div uk-toggle="target: #my-id2" type="button" class="buton-modal uk-overflow-hidden uk-border-rounded">
                        <img src="@/assets/EvenimenteOas/eventoas6.webp" class="uk-overflow-hidden uk-border-rounded  "   width="" alt="">
                          <div class="uk-position-bottom-center uk-width-1-1 uk-text-secondary uk-background-primary uk-padding-small uk-border-rounded overlayapa fontnormal">Beercrafters</div>
                      </div>
                                <!-- This is the modal -->
                          <div id="my-id2" uk-modal>
                              <div class="uk-modal-dialog uk-modal-body uk-flex uk-flex-column uk-flex-middle">
                                    <h2 class="uk-modal-title"></h2>
                                    <img src="@/assets/EvenimenteOas/eventoas7.webp" class="elementmodal"  width="" alt="">
                                    <img src="@/assets/EvenimenteOas/eventoas4.webp" class="elementmodal" width="" alt="">

                              </div>
                            </div>

              </li>
              <li>
                        <div uk-toggle="target: #my-id3" type="button" class="buton-modal uk-overflow-hidden uk-border-rounded">
                        <img src="@/assets/EvenimenteOas/eventoas10.webp" class="uk-overflow-hidden uk-border-rounded  "   width="" alt="">
                          <div class="uk-position-bottom-center uk-width-1-1 uk-text-secondary uk-background-primary uk-padding-small uk-border-rounded overlayapa fontnormal">Vizită la fabrică</div>
                      </div>
                                <!-- This is the modal -->
                          <div id="my-id3" uk-modal>
                              <div class="uk-modal-dialog uk-modal-body uk-flex uk-flex-column uk-flex-middle">
                                    <h2 class="uk-modal-title"></h2>
                                    <img src="@/assets/EvenimenteOas/eventoas11.webp" class="elementmodal"  width="" alt="">
                                    <img src="@/assets/EvenimenteOas/eventoas12.webp" class="elementmodal" width="" alt="">
                                    <img src="@/assets/EvenimenteOas/eventoas13.webp" class="elementmodal" width="" alt="">

                              </div>
                            </div>

              </li>
              <li>
                        <div uk-toggle="target: #my-id34" type="button" class="buton-modal ">
                        <img src="@/assets/EvenimenteOas/eventoas15.webp" class="uk-overflow-hidden uk-border-rounded"  width="" alt="">
                          <div class="uk-position-bottom-center uk-width-1-1 uk-text-secondary uk-background-primary uk-padding-small uk-border-rounded overlayapa fontnormal">Expo industry</div>
                      </div>
                                <!-- This is the modal -->
                          <div id="my-id34" uk-modal>
                              <div class="uk-modal-dialog uk-modal-body uk-flex uk-flex-column uk-flex-middle">
                                    <h2 class="uk-modal-title"></h2>
                                    <img src="@/assets/EvenimenteOas/eventoas5.webp" class="elementmodal"  width="" alt="">
                                    <img src="@/assets/EvenimenteOas/eventoas8.webp" class="elementmodal" width="" alt="">

                              </div>
                            </div>

              </li>

            </ul>
          </div>

        </div>


    <div id="produse" class="view  uk-background-secondary uk-text-primary "><br>


        <section class="uk-width-2-5@m uk-margin-auto justify">
        <h2 class="uk-text-primary uk-padding">
Apa Oaș -<br class="uk-hidden@m"> o picătură din Țara Oașului</h2>
<div class="uk-padding">
  <p>
Fiecare regiune din România are propriile puncte forte și atuuri, iar Țara Oașului este, printre altele, locul unde oamenii au înțeles rostul vieții prin tradiții, meșteșuguri și prin resursele naturii.
</p><p>
Aici, oamenii înțeleg să adopte un stil de viață bazat pe simplitate și pe ciclurile naturii, iar sătenii știu bine că resursele acesteia sunt atât ghidul sănătății fizice, cât și a celei sufletești.
</p><p>Apa minerală naturala și carbogazificată OAȘ este dovada vie că resursele naturii de pe meleagurile oșenești pot fi accesibile de către orice persoană care alege să ne consume produsele.
Apele noastre se găsesc în magazine sub diferite forme și dimensiuni. De la apă minerală naturală sau apă minerală carbogazificată, puteți încerca apele OAȘ la diferite dimensiuni, de la cele mai mici, până la cele mai mari.
</p><p>
Apele noastre provin din resurse binefăcătoare, naturale, ce au proprietăți terapeutice, apele OAȘ provin din izvoare cu apă minerală pură și sănătoasă. Acest izvor poartă denumirea străveche de „borcut”, care se regăsește în mai multe localități din Țara Oașului, precum Negrești-Oaș, Vama, Certeze sau Bixad.
</p><p>
Încă din cele mai străvechi timpuri, oșenii au înțeles cât de important și sănătos este să consume apă provenită din izvoarele proprii, deoarece ea conține toate minerale necesare și cvasi indispensabile organismului, dar și pentru efectele sale curative, deoarece apa din aceste surse este calcică, sulfuroasă, clorosodică și carbogazoasă, fiind chiar inclusă în categoria apelor minerale naturale și apelor medicinale.
</p>

</div>
 
  </section>

      <div uk-slider="autoplay:true;autoplay-interval:3000"  class="uk-padding-small uk-padding-bottom">
        <ul class="tabelelemente uk-slider-items uk-child-width-1-1   uk-child-width-1-3@m  "  >
          <li>
            <img src="@/assets/sticle/plata05.webp"  width="" alt="">
            <div class="uk-position-bottom-center uk-width-1-1 uk-text-secondary uk-background-primary uk-padding-small uk-border-rounded overlayapa">Apa plată 0.5l</div>
          </li>
          <li>
            <img src="@/assets/sticle/min05.webp" width="" alt="">
            <div class="uk-position-bottom-center uk-width-1-1 uk-text-secondary uk-background-primary uk-padding-small uk-border-rounded overlayapa">Apa minerală 0.5l</div>
          </li>
          <li>
            <img src="@/assets/sticle/min15.webp" width="" alt="">
            <div class="uk-position-bottom-center uk-width-1-1 uk-text-secondary uk-background-primary uk-padding-small uk-border-rounded overlayapa">Apa minerală 1.5l</div>
          </li>
          <li>
            <img src="@/assets/sticle/plata20.webp" width="" alt="">
            <div class="uk-position-bottom-center uk-width-1-1 uk-text-secondary uk-background-primary uk-padding-small uk-border-rounded overlayapa">Apa plată 2l</div>
          </li>
          <li>
            <img src="@/assets/sticle/min20.webp" width="" alt="">
            <div class="uk-position-bottom-center uk-width-1-1 uk-text-secondary uk-background-primary uk-padding-small uk-border-rounded overlayapa">Apa minerală 2l</div>
          </li>
          <li>
            <img src="@/assets/sticle/plata50.webp" width="" alt="">
            <div class="uk-position-bottom-center uk-width-1-1 uk-text-secondary uk-background-primary uk-padding-small uk-border-rounded overlayapa">Apa plată 5l</div>
          </li>
          <li>
            <img src="@/assets/sticle/min25.webp" width="" alt="">
            <div class="uk-position-bottom-center uk-width-1-1 uk-text-secondary uk-background-primary uk-padding-small uk-border-rounded overlayapa">Apa minerală 2.5l</div>
          </li>
        </ul>
      </div>
        <div class="uk-width-2-5@m uk-margin-auto">
              <h3 class=" uk-text-primary uk-margin-top uk-padding">
            De ce e atât de specială apa Oaș?</h3>
        <div class="uk-padding">Ei bine, potrivit unor studii științifice, publicate chiar în „Ghidul apelor minerale din România”, publicat cu suportul tehnic și științific al Societății Naționale a Apelor Minerale SNAM S.A., apa minerală naturală Oaș provine dintr-o zonă situată în cadrul depresiunii Oașului, la limita vestică a Munților Gutâi, între localitățile Negrești și Certeze, care se află la o altitudine de 220 m. <br>Acviferul hidromineral carbogazos prezintă o extindere redusă și este localizat în formațiuni poros permeabile vulcanogen-sedimentare constituite din nisipuri fine, delimitate de depozite argiloase la suprafață și marnoase în adâncime.
        </div>
        </div>
    </div>
    <div id="proprietati" class="view uk-container@m uk-background-secondary uk-text-primary uk-flex uk-flex-row uk-padding-top uk-padding-bottom"><span class="uk-container">
        <img src="@/assets/water-bottle.png" class="uk-margin-bottom uk-margin-top">
        <section class="uk-width-3-5@m uk-margin-auto">
        <h2 class="uk-text-primary">
Proprietăți</h2>

Apa este esența vieții, iar Apa Oaș, prin prisma conținutului său de CO2, dar și datorită caracterului său hidrogen carbonat sodic, stimulează digestia și este recomandată ca un excelent adjuvant în terapiile diferitelor boli hepatice cronice, precum și în cazul afecțiunilor biliare cronice.
<br>Ne încântă faptul că, calitatea produselor noastre este atestată oficial prin buletinul de analiză nr. 9398/14.10.2016 eliberat de către Laboratorul SNAM București, acreditat RENAR.

  <br><button uk-toggle="target: #produses" class="uk-button uk-background-primary uk-text-secondary uk-margin-top uk-margin-bottom" type="button">Arată tot</button>
  <div id="produses" hidden class="uk-container uk-padding-bottom justify">
Aici, oamenii înțeleg să adopte un stil de viață bazat pe simplitate și pe ciclurile naturii, iar sătenii știu bine că resursele acesteia sunt atât ghidul sănătății fizice, cât și a celei sufletești.
<br>Apa minerală naturala și carbogazificată OAȘ este dovada vie că resursele naturii de pe meleagurile oșenești pot fi accesibile de către orice persoană care alege să ne consume produsele.
Apele noastre se găsesc în magazine sub diferite forme și dimensiuni. De la apă minerală naturală sau apă minerală carbogazificată, puteți încerca apele OAȘ la diferite dimensiuni, de la cele mai mici, până la cele mai mari.
 <br>
Apele noastre provin din resurse binefăcătoare, naturale, ce au proprietăți terapeutice, apele OAȘ provin din izvoare cu apă minerală pură și sănătoasă. Acest izvor poartă denumirea străveche de „borcut”, care se regăsește în mai multe localități din Țara Oașului, precum Negrești-Oaș, Vama, Certeze sau Bixad.<br>
Încă din cele mai străvechi timpuri, oșenii au înțeles cât de important și sănătos este să consume apă provenită din izvoarele proprii, deoarece ea conține toate minerale necesare și cvasi indispensabile organismului, dar și pentru efectele sale curative, deoarece apa din aceste surse este calcică, sulfuroasă, clorosodică și carbogazoasă, fiind chiar inclusă în categoria apelor minerale naturale și apelor medicinale.
 

</div>
<h3 class=" uk-text-primary uk-margin-top ">
Cum capătă apa aceste proprietăți? </h3>
<div>
Este arhicunoscut faptul că apa provine de fapt din precipitații, iar atunci când își urmează circuitul în natură, ea intră în sol și trece succesiv prin straturile solului, ce conțin diferite săruri și minerale benefice organismului. Ea spală, de fapt, și dizolvă anumite minerale și săruri solubile, păstrând cele mai importante proprietăți, în funcție și de straturile în care ele s-au format. Tot de aceste straturi depinde și tipul de apă ce rezultă: ape clorurate, sulfuroase sau hidrogen-carbonate. Mai apoi, în funcție de cantitatea de dioxid de carbon ce rămâne în apă, ea poate fi carbogazoasă, necarbogazoasă, decarbogazificată, natural îmbogățită cu dioxid de carbon sau natural carbogazificată. Ei bine, din categoria apelor natural carbogazificate face parte și apa noastră minerală, Oaș.
</div>
<h3 class=" uk-text-primary uk-margin-top ">
Ce beneficii are apa Oaș pentru organism și cum putem înțelege mai bine beneficiile sale?
    </h3>

<div>
Apa minerală Oaș prezintă multiple beneficii pentru organism. Așa cum s-a dovedit prin studiile asupra aparatului digestiv uman, efectuate de specialiști din cadrul Spitalului Universitar CF Cluj-Napoca privind apa minerală Oaș, rezultatele arată că prin prisma compoziției sale chimice, apa noastră are beneficii multiple asupra organismului uman, cu precădere asupra funcțiilor hepato-biliare gastrointestinale.
Conform studiilor efectuate de-a lungul timpului, apa este benefică pentru depozitele de calciu și magneziu, care acționează împotriva carenței de săruri minerale. Magneziul ne ajută la creșterea capacității de rezistență a organismului, crește capacitatea de concentrare și are rol deosebit de important în coordonarea dintre mușchi și sistemul nervos central.
Studii importante efectuate în domeniu arată că persoanele care aleg să consume apă bogată în calciu și magneziu sunt supuse la un risc mult mai redus de a face atac de cord sau atac cerebral, șansele fiind de până la 10% mai reduse. De asemenea, sulfații din apă au efecte diuretice și laxative, iar potasiul joacă un rol important în prevenirea deshidratării și sunt benefice în problemele privind retenția de apă.
</div>
<h3 class=" uk-text-primary uk-margin-top ">
Alte beneficii asupra organismului 
    </h3>
<div>
Substanțele din compoziția apei noastre au și alte beneficii, cum ar fi stimularea mușchilor și a sistemului nervos central, ajută la coagularea sângelui în cazul rănilor deschise și, totodată, ajută la transportul oxigenului.
Apele minerale pot fi folosite atât în cadrul curelor interne, cât și externe, ele ajută la ameliorarea bolilor digestive, ajută la normalizarea funcțiilor gastro-intestinale, acționează în procesele inflamatorii ale aparatului uro-genital, ele sunt un excelent stimulent și pot îmbunătăți peristaltismul (mișcarea de contracție a musculaturii) stomacului și al intestinelor, după caz ajută la mărirea sau micșorarea acidității gastrice, stimulează circulația la nivelul stomacului și ficatului. De asemenea, o funcție extrem de importantă e și cea de lichidare a foamei de oxigen la nivelul țesuturilor și crește rezistența organismului la diferite boli.
 
Calitățile și beneficiile apei minerale este sunt în continuă creștere, aceste lucru fiind observat și în industria farmaco-cosmetică, deoarece ajută la hidratarea, hrănirea și mineralizarea corespunzătoare a pielii și a întregului organism.

</div>
 
  </section>
  </span></div>

  <div id="siguranta" class="view uk-container@m uk-background-secondary uk-text-primary uk-flex uk-flex-row"><span class="uk-container">
      <img src="@/assets/shield.png">
      <section class="uk-width-1-2@m uk-margin-auto">
      <h2 class="uk-text-primary">Siguranță oferită</h2>Societatea este certificată de către Organismul pentru Certificarea Sistemelor de Management al Calității pentru ISO 9001 - QSCERT; 14001 - QSCERT, 22000 - QSCERT.<br><button uk-toggle="target: #showww2" class="uk-button uk-background-primary uk-text-secondary uk-margin-top" type="button">Arată tot</button>
<p id="showww2" hidden class="uk-container">
  <img src="@/assets/certificate/1_CER.webp">
    <img src="@/assets/certificate/2_CER.webp">
      <img src="@/assets/certificate/3_CER.webp">
        <img src="@/assets/certificate/4_CER.webp">
          <img src="@/assets/certificate/5_CER.webp">
            <img src="@/assets/certificate/6_CER.webp">

</p>
</section>
</span></div>

  <section class="parteneri uk-background-secondary uk-overflow-hidden" id="camion">
    <OpticaView></OpticaView>
  </section>

    <FooterView id="contact" class="footer"></FooterView>

  </div>

</template>

<script>

  import navbar from '../components/navbar.vue'
  import HomeView from './HomeView.vue'
  import UIkit from 'uikit'
  import vue from 'vue'
  import FooterView from './FooterView.vue'
  import OpticaView from './OpticaView.vue'
  export default {
    name: 'Home',
    data: function() {
      return {
        nav: true
      }
    },
    components: {
      HomeView: HomeView,
      navbar: navbar,
      FooterView: FooterView,
      OpticaView: OpticaView
    }
  }

</script>

<style scoped lang="scss">
.buton-modal{
  background:none;
  border:none;
}
.elementmodal{
  padding-bottom:1em;
}
.fontnormal{font-family: 'Montserrat', sans-serif;}
.uk-modal-body {
  margin-bottom:50vh;
  padding:0;
  background:#2559A6;
}
  .overlayapa{bottom:-50px

  }
  .tabelelemente{padding-bottom:10vh;
    li{
      img{

          height:40vh;
      }
    }
  }
  @media (orientation: portrait) {
  }

  .titlu {
    text-shadow: 0 0 6px #0009;
    font-size: 1.5rem;
    line-height: 1.3;
    color: white;
    font-weight: 600;
    letter-spacing: 3px;
    margin-bottom: 0.5em;
  }
  .subtitlu {
    font-size: 0.75rem;
    letter-spacing: 0.0625rem;
    line-height: 1.5;
  }
  .cartonas {
    height: 80vh;
    width: 100%;
    z-index: 1;
    box-shadow: 0 10px 50px #292929 inset;
  }
  .button {
    padding: 1em;
    margin-bottom: 2.5em;
    cursor: pointer;
    text-decoration: none;
    color: black;
  }
  .navbar {
    overflow: hidden;
    width: 100%;
    position: absolute;
  }
  .view {
    min-height: 90vh;
    width: 100%;
    position: relative;
    z-index: +9;
  }
  .one {
    height: 70vh;
    z-index: +10;
    min-height: 70vh;
  }
  .two {min-height:50vh;padding-top:10vh;
  }
  .three {
  }
  .four {
    height: 100vh;
  }
  .five {
    height: 100vh;
  }
  .six {
  }
  .seven {
    height: 30vh;
    background-color: grey;
  }
  .cincicontainer {
    box-shadow: 0 60px 100px #086569 inset, 0 -60px 100px #086569 inset;
  }
  @media (orientation: landscape) {
    .one{height:100vh;

    }
    .despren{
      width:40vw;

    }
  }
  .parteneri {
    min-height: 50vh;
  }
  .footer {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }
  .despretitlu{
    font-size: 2em;
  }
  .despren{
    font-weight: 300;
  }
  .footer{
    font-weight: 300;

  }
  .justify{
    
  text-align: justify;
  text-justify: inter-word;text-align-last: center;
  }
</style>
